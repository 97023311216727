$deco-wave: '../images/mobile/deco-wave-footer.png';

.footer {
  .link {
    margin: spacing(1, 1.5);
  }
  margin-top: $spacing8;
  padding-top: $spacing3;
  padding-bottom: $spacing8;
  color: $palette-common-white;
  position: relative;
  z-index: 10;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 32px;
      a {
        text-decoration: none;
        font-size: 14px;
        color: $palette-common-white;
        &:hover {
          color: $palette-primary-light
        }
      }
    }
  }
  .title-nav {
    color: $palette-common-white;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: $spacing3;
    font-weight: $font-bold
  }
  &-desc{
    display: block;
    font-size: 14px;
    margin-bottom: $spacing2
  }
  &.invert {
    p {
      @include palette-text-primary;
    }
    li a {
      @include palette-text-primary;
    }
    .logo {
      h6 {
        @include use-theme(color, $palette-primary-dark, $palette-common-white)
      }
    }
    .title-nav {
      @include use-theme(color, $palette-primary-dark, $palette-primary-light)
    }
  }
  .collapsible {
    border: none;
    box-shadow: none;
    max-width: 480px;
    margin: 0 auto;
  }
  .collapsible-header {
    background: none;
    box-shadow: none;
    border: none;
    padding: $spacing1 1rem;
    h6 {
      font-size: 15px !important;
      font-weight: $font-bold;
    }
    i {
      margin-right: 0
    }
  }
  .collapsible-body {
    border: none;
    margin-bottom: $spacing4
  }
  .logo {
    text-align: center;
    margin-bottom: $spacing3;
    img {
      width: 64px;
    }
    h6 {
      color: $palette-common-white;
    }
  }

  .socmed {
    display: flex;
    justify-content: center;
    margin-bottom: $spacing4;
    a {
      margin: $spacing1;
      color: $palette-primary-dark;
      background: $palette-secondary-main;
      width: 36px;
      height: 36px;
      .icon {
        color: $palette-common-white
      }
    }
    .icon {
      font-size: 24px
    }
  }
  .select-lang.select-outlined .select-wrapper {
    margin: 0 auto;
    width: 200px;
    display: inherit;
    margin-top: $spacing2;
    .caret {
      fill: $palette-common-white;
    }
    input.select-dropdown {
      box-shadow: 0 1.5px 12px 2px rgba(0, 0, 0, 0.06);
      border-color: rgba(255, 255, 255, 0.3);
      color: $palette-primary-light;
      height: 2.5rem;
      line-height: 2.5rem;
    }
  }
  .site-map-item {
    @include breakpoints-down('md') {
      padding-bottom: 0 !important;
      padding-top: 0 !important
    }
  }
  .accordion-icon {
    padding: 0
  }
  &.invert {
    @include palette-text-primary;
    p {
      @include palette-text-primary;
    }
    li a {
      @include palette-text-primary;
    }
    .logo {
      h6 {
        color: $palette-primary-dark
      }
    }
    .title-nav {
      @include use-theme(color, $palette-primary-dark, $palette-primary-light)
    }
    .select-lang.select-outlined .select-wrapper {
      .caret {
        @include use-theme(fill, $light-text-primary, $dark-text-primary)
      }
      input.select-dropdown {
        @include use-theme(border-color, $light-text-secondary, $dark-text-secondary);
        @include palette-text-primary
      }
    }
  }
}


.footer-deco {
  position: relative;
  top: 0;
  @include use-theme(background-image, linear-gradient(30deg, $palette-primary-main 50%, $palette-secondary-main 140%), linear-gradient(30deg, $palette-primary-dark 50%, $palette-secondary-dark 140%));
  padding-top: $spacing20 * 2;
  margin-top: -360px;
  @include breakpoints-up(xl) {
    padding-top: $spacing20;
    margin-top: $spacing20
  }
  @include breakpoints-down(sm) {
    padding-top: $spacing15 * 2
  }
  @include breakpoints-down(xs) {
    padding: spacing(10, 3, 0)
  }
  &:before {
    z-index: 1;
    content: "";
    width: 100%;
    height: 500px;
    position: absolute;
    top: $spacing10;
    @include left(0);
    opacity: 0.1;
    background: url($deco-wave) no-repeat transparent;
    background-size: 100%;
  }
  .deco-top {
    height: 300px;
    width: 100%;
    position: absolute;
    top: -10px;
    &.back {
      @include use-theme(fill, $palette-primary-light, #303030);
    }
    &.cover {
      @include use-theme(fill, #fff, $palette-primary-light);
      @include use-theme(opacity, 0.8, 0.2);
    }
    @include breakpoints-up(xl) {
      display: none
    }
    @include breakpoints-up(lg) {
      transform: scale(1.5, 1)
    }
    @include breakpoints-down(md) {
      transform: scale(1.2, 0.7);
      top: -80px;
    }
    @include breakpoints-down(xs) {
      transform: scale(0.5, 0.5);
      top: -90px;
      @include left(-330px);
      width: 1280px;
    }
  }
  .decoration {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    @include left(0);
    overflow: hidden;
    clip: rect(0, auto, auto, 0);
    svg {
      fill: $palette-secondary-main;
      opacity: 0.15;
      position: fixed;
      top: 0
    }
  }

  .action {
    text-align: center;
    position: relative;
    z-index: 1;
    padding-top: $spacing5;
    @include breakpoints-down(md) {
      padding-top: 0;
      padding-bottom: $spacing3
    }
    @include breakpoints-down(xs) {
      padding-top: $spacing15;
      padding-bottom: 0
    }
    h4 {
      color: $palette-common-white;
      margin-bottom: $spacing6
    }
  }

  .btn-area {
    margin: spacing(5, 0);
    justify-content: center;
    position: relative;
    display: flex;
    @include breakpoints-down(xs) {
      justify-content: space-around;
    }
    a {
      @include breakpoints-up(sm) {
        @include margin-right($spacing2)
      }
      @include breakpoints-down(xs) {
        margin: 4px;
      }
      img {
        width: 160px;
        @include breakpoints-down(xs) {
          width: 100%
        }
      }
    }
  }
}

$deco-wave-light: '../images/mobile/deco-wave-light.png';
$deco-wave-dark: '../images/mobile/deco-wave-dark.png';
$deco-line-light: '../images/mobile/deco-line-light.png';
$deco-line-dark: '../images/mobile/deco-line-dark.png';


#banner {
  .root {
    position: relative;
    display: block
  }

  .background {
    position: absolute;
    height: 720px;
    top: 0px;
    @include right(0);
    width: 55%;
    @include breakpoints-up(lg) {
      width: 50%
    }
    @include breakpoints-down(sm) {
      height: 260px;
    }
    @include breakpoints-down(xs) {
      display: none;
    }
    img {
      position: absolute;
      @include right(0);
      top: 0;
    }
  }

  .deco-inner {
    position: absolute;
    top: 0;
    @include right(0);
    opacity: 0.2;
    @include breakpoints-down(md) {
      display: none;
    }
  }

  .gradient {
    background-image: linear-gradient(30deg, $palette-primary-main 50%, $palette-secondary-main 140%);
    width: 100%;
    height: 100%;
    img {
      display: block
    }
  }

  .text {
    height: 680px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10;
    @include breakpoints-up(sm) {
      @include padding-left(20px);
      @include padding-right($spacing4);
    }
    @include breakpoints-down(xs) {
      justify-content: flex-start;
      padding-top: $spacing20;
      height: 560px;
    }
    h3 {
      margin-top: $spacing10;
      @include breakpoints-down(xs) {
        margin-top: 0;
        text-align: center
      }
      strong {
        font-weight: $font-bold;
        @include use-theme(color, $palette-primary-main, $palette-primary-light);
      }
    }
    h5 {
      @include palette-text-secondary;
      margin: spacing(3, 0, 4);
      @include breakpoints-down(xs) {
        text-align: center
      }
    }
  }

  .btn-area {
    text-align: center;
    display: flex;
    @include breakpoints-down(xs) {
      justify-content: space-around
    }
    a {
      @include breakpoints-up(sm) {
        @include margin-right($spacing2)
      }
      @include breakpoints-down(xs) {
        margin: 4px
      }
      img {
        width: 160px;
        @include breakpoints-down(xs) {
          width: 100%
        }
      }
    }
  }

  .decoration {
    position: relative;
    height: 100%
  }

  .deco-wave {
    position: absolute;
    top: 40px;
    @include left(-1px);
    width: 102%;
    height: 100%;
    [dir="rtl"] & {
      transform: scale(-1, 1)
    }
    @include use-theme(background-image, url($deco-wave-light), url($deco-wave-dark));
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 720px;
    @include breakpoints-down(sm) {
      background-size: 100% 240px;
    }
  }

  .deco-line {
    position: absolute;
    @include left(-10px);
    top: -10px;
    opacity: 0.56;
    width: 100%;
    height: 100%;
    [dir="rtl"] & {
      transform: scale(-1, 1)
    }
    @include use-theme(background-image, url($deco-line-light), url($deco-line-dark));
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 700px;
  }

  .phone-illustration {
    position: relative;
    height: 100%;
    @include breakpoints-down(md) {
      @include right($spacing10 * -1);
    }
    @include breakpoints-down(sm) {
      @include use-theme(opacity, 0.8, 0.4);
      @include right(0);
      top: 200px;
    }
    @include breakpoints-down(xs) {
      @include right(-20px);
      @include use-theme(opacity, 0.4, 0.2)
    }
    img {
      position: absolute;
    }
  }

  .phone {
    width: 600px;
    @include right(-40px);
    bottom: 0
  }

  .widget-top {
    width: 150px;
    bottom: 330px;
    @include left(130px);
    &.fragment-fadeUp {
      transition-delay: 0.2s
    }
  }

  .widget-left {
    width: 170px;
    bottom: 180px;
    @include left(110px);
    &.fragment-fadeUp {
      transition-delay: 0.3s
    }
  }

  .widget-right {
    width: 170px;
    bottom: 240px;
    @include right(80px);
    &.fragment-fadeUp {
      transition-delay: 0.4s
    }
  }
}

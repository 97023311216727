$deco-light-bottom: '../images/mobile/deco-light-top.svg';
$deco-dark-bottom: '../images/mobile/deco-dark-top.svg';

#testimonials {
  .root {
    .theme--light & {
      @include bg-color-lighten($palette-primary-light, 0.8)
    }
    .theme--dark & {
      @include bg-color-darken($palette-primary-light, 0.65)
    }
    position: relative;
    z-index: 30;
    min-height: 400px;
    padding-top: $spacing20
  }

  .carousel-wrap {
    position: relative;
    z-index: 1;
    text-align: center
  }

  .item {
    &:focus {
      outline: none
    }
  }

  .content {
    font-style: italic;
    font-size: 18px;
    @include breakpoints-down(xs) {
      font-size: 16px;
    }
  }

  .name {
    @include use-theme(color, $palette-primary-main, $palette-primary-light);
    margin: spacing(2, 0, 4);
    strong {
      font-weight: $font-bold
    }
  }

  .carousel-text {
    .item {
      position: relative
    }
  }

  .carousel-avatar {
    margin: 0 auto;
    @include breakpoints-up(sm) {
      width: 768px;
    }
    .item {
      cursor: pointer;
      height: 140px;
      padding-top: $spacing2
    }
    .slick-center {
      .avatar {
        opacity: 1;
        transform: scale(1.4)
      }
    }
  }

  .avatar {
    border: 4px solid white;
    width: 80px !important;
    height: 80px !important;
    margin: 0 auto;
    opacity: 0.7;
    transition: all 0.3s ease
  }

  .deco-bg-bottom {
    @include use-theme(background-image, url($deco-light-bottom), url($deco-dark-bottom));
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% auto;
    transform: rotate(180deg) scale(1, 0.4);
    position: absolute;
    width: 100%;
    height: 470px;
    @include left(0);
    bottom: -145px;
    &:after {
      content: "";
      @include use-theme(background-image, url($deco-light-bottom), url($deco-dark-bottom));
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: 100% auto;
      position: absolute;
      width: 100%;
      height: 470px;
      opacity: 0.5;
      @include left(60px);
      top: 60px;
    }
  }
}

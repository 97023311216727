#showcase {
  .root {
    @include use-theme(background-image, linear-gradient(185deg, $palette-primary-main 50%, $palette-secondary-main 100%), linear-gradient(185deg, $palette-primary-dark 50%, $palette-secondary-dark 100%));
    padding-top: $spacing20 * 4;
    @include breakpoints-down(md) {
      padding-top: $spacing2 * 35;
    }
    @include breakpoints-up(xl) {
      padding-top: $spacing2 * 25;
    }
    @include breakpoints-down(xs) {
      padding: spacing(60, 3, 0),
    }
    @media only screen and (max-width: 330px) {
      padding: spacing(60, 0, 0);
    }
  }

  .deco-top {
    height: 300px;
    width: 100%;
    position: absolute;
    top: -3px;
    @include left(0);
    &.back {
      fill: $palette-primary-light;
    }
    &.cover {
      @include use-theme(fill, #FFF, #000);
      @include use-theme(opacity, 0.8, 0.65);
    }
    @include breakpoints-up(lg) {
      transform: scale(1.5, 1)
    }
    @include breakpoints-up(xl) {
      display: none
    }
  }

  .deco-bottom {
    height: 300px;
    width: 100%;
    position: absolute;
    bottom: -70px;
    z-index: 21;
    @include breakpoints-up(lg) {
      transform: scale(1.5, 1)
    }
    @include breakpoints-up(xl) {
      display: none
    }
    @include breakpoints-down(sm) {
      width: 1280px;
      @include left(-50%);
    }
    @include breakpoints-down(xs) {
      display: none
    }
    &.back {
      fill: $palette-primary-light;
    }
    &.cover {
      @include use-theme(fill, #FFF, #000);
      @include use-theme(opacity, 0.8, 0.65);
    }
  }

  .deco-bottom-mobile {
    display: none;
    height: 140px;
    width: 140%;
    @include left(-20%);
    position: absolute;
    bottom: -40px;
    border-radius: 50%;
    z-index: 21;
    .theme--light & {
      @include bg-color-lighten($palette-primary-light, 0.8)
    }
    .theme--dark & {
      @include bg-color-darken($palette-primary-light, 0.65)
    }
    &:before {
      border-radius: 50%;
    }
    @include breakpoints-down(xs) {
      display: block;
    }
  }

  .invert-parallax {
    filter: brightness(0) invert(1);
    position: absolute;
    opacity: 0.5;
    @include left(0);
    bottom: 600px;
    width: 100%;
    height: 100%;
  }

  .carousel-wrap {
    position: relative;
    z-index: 20;
    @include breakpoints-up(lg) {
      padding-top: $spacing10
    }
    @include breakpoints-down(xs) {
      padding-top: $spacing5
    }
  }

  .item {
    position: relative;
    &:focus {
      outline: none
    }
  }

  .frame {
    padding: $spacing1;
    margin: 0 auto;
    overflow: visible;
    border-radius: 20px;
    background: $palette-common-white !important;
    img {
      display: block;
      width: 100%
    }
  }

  .carousel-side {
    @include breakpoints-up(xl) {
      max-width: 1440px;
      margin: 0 auto
    }
    @include breakpoints-down(xs) {
      display: none
    }
    .frame {
      width: 200px;
      height: 410px;
      @include breakpoints-down(md) {
        width: 166px;
        height: 365px;
      }
    }
  }

  .carousel-center {
    width: 320px;
    overflow: hidden;
    @include breakpoints-up(sm) {
      position: absolute;
      @include left(50%);
      bottom: 0;
      [dir="ltr"] & {
        transform: translateX(-50%);
      }
      [dir="rtl"] & {
        transform: translateX(50%);
      }
    }
    @include breakpoints-up(lg) {
      bottom: -60px;
    }
    @include breakpoints-down(xs) {
      margin: 0 auto
    }
    .frame {
      width: 249px;
      height: 548px;
      position: relative;
      @include breakpoints-between(sm, md) {
        width: 166px;
        height: 365px;
      }
      @include breakpoints-between(md, lg) {
        width: 166px;
        height: 365px;
      }
      img {
        transition: all 0.3s ease-out;
        transform: translateY(30px)
      }
    }
    .slick-list {
      overflow: visible
    }
    .slick-active {
      img {
        transform: translate(0)
      }
      .widget {
        transform: scale(1.2);
        opacity: 1;
      }
    }
  }

  .widget {
    position: absolute;
    top: 30px;
    @include left(0);
    transform: scale(1);
    opacity: 0;
    transition: all 0.4s ease-out;
    transition-delay: 0.3s;
    z-index: 2;
    img {
      width: 100%
    }
  }

  .pagination {
    z-index: 90;
    position: relative;
    @include breakpoints-down(xs) {
      margin-top: $spacing8 * -1
    }
    ul {
      position: absolute;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      bottom: $spacing6 * -1;
      li {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin: 0 5px;
        padding: 0;
        background: $palette-secondary-main;
        border-radius: 15px;
        transition: all 0.5s ease-out;
        &.active {
          width: 40px;
        }
        &:hover {
          opacity: 0.5
        }
        button {
          opacity: 0;
          cursor: pointer;
          padding: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
$error-deco: '../images/mobile/error-deco.png';

.error-wrap {
  width: 100%;
  min-height: 640px;
  display: flex;
  align-items: center;
  @include breakpoints-up(xs) {
    padding-top: $spacing20
  }
  .container {
    #main-wrap & {
      width: 100%;
    }
  }
  .flex {
    display: flex;
    justify-content: center
  }

  .artwork {
    position: absolute;
    top: -20px;
    @include left(0);
  }

  .deco {
    position: relative;
    height: 250px;
    h3 {
      color: $palette-common-white;
      font-size: 96px;
      text-transform: capitalize;
      font-weight: 700;
      padding-top: 40px;
      @include padding-left(20px);
      position: relative;
      z-index: 1
    }
  }

  .text {
    @include breakpoints-up(sm) {
      @include padding-left($spacing5);
      @include border-left(1px solid);
      @include use-theme(border-color, $light-divider, $dark-divider)
    }
    @include breakpoints-down(sm) {
      text-align: center;
      margin: spacing(5, 0, 20);
    }
    h4 {
      font-weight: $font-bold;
      margin-bottom: $spacing4
    }
  }
  .button {
    margin-top: $spacing4
  }
}

